.bio-column-1 {
    text-align: center;
    
    a img:hover {
        filter: opacity(0.5) drop-shadow(0 0 0 blue);
    }
}

.name-container {
    margin-top: 20px;

    h3, h4 {
        font-style: normal;
        letter-spacing: 0.125em;
        text-align: center;
        text-transform: uppercase;
        font-weight: 100;
        line-height: 1;
    }

    h4 {
        text-transform: none;
        font-variant: small-caps;
    }
}

.links-container {
    margin-top:20px;
}