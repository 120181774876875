.icon-credits-container {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding-bottom: 10px;

    a {
        cursor: pointer;
        text-decoration: underline !important;
        text-underline-offset: 5px;
        

        &:hover {
            font-size: 105%;
            color: rgb(190, 190, 190) !important;
        }
    }

    .icon-credits {
        display: flex;
        flex-direction: row;
    }
}